<template>
    <div class="wrapper">
        <headBack :showKef='true'>
            <template v-slot:title>
                <div class='title'>
                    服务详情
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='top'>
            <img :src="goodsdetail.headImg" class='detail_top'/>
            <div class='title'>
                <div class='border_btm'>
                    <div class='title_top'>
                        <div class='title_name'>{{goodsdetail.serviceName}}</div>
                        <!--                         <div class='sale_num'>已售:2</div>-->
                    </div>
                    <div class='dero'>服务时间：{{goodsdetail.content.serviceTime}}</div>
                    <div class='institution'>本服务由北京快天使医护到家护理站为您提供服务</div>
                </div>
            </div>
        </div>
        <div class="time">
            <div class="t_b"
                 v-for="(item,index) in goodsdetail.content.paragraph1.introduce"
                 :key="item"
            >
                {{item}}
            </div>
        </div>
        <div class='line'></div>
        <div class="liuc">
            <div class="l_tit">{{goodsdetail.content.paragraph1.title}}</div>
            <img
                    v-for="(item,index) in goodsdetail.content.paragraph1.img"
                    :src="item"
                    :key="index"
                    alt=""
            />
        </div>
        <div class='line'></div>
        <div class="liuc">
            <div class="l_tit">{{goodsdetail.content.paragraph2.title}}</div>
            <div class="s_t">【服务介绍】</div>
            <div>{{goodsdetail.content.paragraph1.description}}</div>
            <div class="l_box">
                <div
                        class="l_list "
                        v-for="(item,index) in goodsdetail.content.paragraph2.contents1"
                        :class="{'tuz':index%2 !=0}"
                >
                    <div class="list_t">
                        <p class="t1">{{item.subtitle}}</p>
                        <p v-for="(item,index) in item.introduce"
                           :key="item"
                        >
                            <span>•</span> &nbsp;&nbsp;{{item}}
                        </p>
                    </div>
                    <img :src="item.img" alt="">
                </div>
            </div>
        </div>
        <div
                class="more_t"

        >
            <p class="tit_m">{{goodsdetail.content.paragraph2.contents2.title}}</p>
            <p
                    v-for="(item,index) in goodsdetail.content.paragraph2.contents2.introduce"
                    :key="item"
            >{{item}}</p>
        </div>
        <div class="service_t">
            <div class="s_t">【服务须知】</div>
            <p
                    v-for="(item,index) in goodsdetail.content.paragraph2.contents3"
                    :key="item"
            >{{item}}</p>
        </div>
        <div class="content">
        </div>
        <div class='btn_btm_cont'>
            <div class='btn_inner'>
                <div class='money'><span class='small'>¥</span>{{goodsdetail.price}}<span class='small'>元起</span></div>
                <van-button type="success" color='#00C291' class='submit_btn' round @click='gotoSorderPage'>立即预约
                </van-button>
            </div>
        </div>

    </div>
</template>

<script>
    import {setWxShareDate} from '@/libs/utils.js'
    import {onMounted, ref, computed,reactive} from "vue"
    import {useRouter, useRoute} from 'vue-router'
    import {useStore} from 'vuex'
    import {Dialog} from 'vant'
    import homeApi from '@axios/home'

    export default {
        name: "serviceDetail",
        setup() {

            let router = useRouter()
            const store = useStore()
            const route = useRoute()

            const scdSvCode = ref('');
            let goodsdetail = ref({
                content:{
                    paragraph1:{
                        title:''
                    },
                    paragraph2:{
                        contents2:{},
                        title:''
                    }
                }
            });

            const getDetail = (roleCode, firsvCode, scdsvCode) => {
                let params = {
                    // id : id,
                    rolecode: roleCode,
                    firsvcode: firsvCode,
                    scdsvcode: scdsvCode
                }
                homeApi.goodsDetail(params).then((res) => {
                    console.log(JSON.parse(res.data.content))
                    goodsdetail.value = res.data;
                    goodsdetail.value.content = JSON.parse(res.data.content);
                    scdSvCode.value = res.data.scdSvCode ? res.data.scdSvCode : '';
                    setWxShareDate(`医护到家：${goodsdetail.value.serviceName}`,goodsdetail.value.headImg)//分享
                })
            }
            getDetail(route.query.rolecode, route.query.firsvcode, route.query.scdsvcode)


          const Recommenduserphone = route.query.Recommenduserphone ?  route.query.Recommenduserphone : '' //护士任务参数 手机号
          const batchNumber = route.query.batchNumber ?  route.query.batchNumber : '' //护士任务参数
            const gotoSorderPage = () => {
                router.push({
                    name: "pzhenSorder",
                    query: {
                        code: goodsdetail.value.firsvcode,
                        roleCode: goodsdetail.value.roleCode,
                        scdSvCode: scdSvCode.value,
                        price: goodsdetail.value.price,
                        Recommenduserphone:Recommenduserphone + '',
                        batchNumber:batchNumber+ ''
                    }
                })
            }

          // 护士推广绑定用户部分
          const setBindNurse =()=> {
            const params ={
              userId:store.state.uid,
              recommenduserphone:Recommenduserphone
            }
            homeApi.setBindUserToNurse(params).then((res) => {


              if(res.code == '0000'){
                Dialog.alert({
                  title:'温馨提示',
                  message:'恭喜您，已跟护士绑定成功',
                  theme:'round-button',
                  confirmButtonColor:'rgb(0, 194, 145)'
                })
              }
              // else{
              //   Dialog.alert({
              //     title:'温馨提示',
              //     message:'很抱歉，您已绑定过护士了',
              //     theme:'round-button',
              //     confirmButtonColor:'rgb(0, 194, 145)'
              //   })
              // }

            })
          }


          if(Recommenduserphone){
            if(store.state.token){//已经登录
              setBindNurse();
            }else{
              Dialog.alert({
                title:'温馨提示',
                message:'尊敬的用户您好，为方便给您提供更好的服务，您可以跟护士做绑定关系，点击立即绑定即可。',
                theme:'round-button',
                confirmButtonText:'立即绑定',
                confirmButtonColor:'rgb(0, 194, 145)'
              }).then(()=> {

                router.push({
                  name : 'login',
                  query : {
                    Recommenduserphone:Recommenduserphone,
                    batchNumber:batchNumber

                  }
                })
              })
            }

          }

            return {
                scdSvCode,
                goodsdetail,
                router,
                gotoSorderPage
            }
        }
    }
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
    .c_list {
        clear: both;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        padding: 11px;

        .fs {
            margin-bottom: 10px;
        }

        .c_title {
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }

        .c_cont {
            margin-top: 10px;
        }
    }

    .liuc {
        font-size: 24px;
        padding: 20px 30px;

        p {
            margin: 0;
        }

        .l_tit {
            font-size: 30px;
            margin-bottom: 40px;
            font-weight: 600;
        }

        img {
            width: 100%;
        }

        .l_box {
            .l_list {
                margin-top: 40px;
                display: flex;

                .list_t {
                    color: #666666;
                    padding-left: 20px;
                    margin-right: 20px;
                    padding-top: 20px;

                    p > span {
                        color: #FC9D9A;
                    }

                    .t1 {
                        font-size: 26px;
                        border-bottom: 1px solid #FC9D9A;
                        padding-bottom: 6px;
                        margin-bottom: 6px;
                        color: #FC9D9A;
                    }
                }

                img {
                    width: 45%;
                }
            }

            .tuz {
                flex-direction: row-reverse;
            }
        }
    }

    .more_t {
        font-size: 24px;
        padding: 20px 30px;
        background: #FEF5F5;
        color: #FC6C7C;

        .tit_m {
            text-indent: 0;
            text-align: center;
        }

        p {
            text-indent: 50px;
            line-height: 36px;
            margin: 0 0 10px 0;
        }
    }

    .service_t {
        padding: 20px 50px;
        font-size: 24px;

        p {
            margin: 0;
            line-height: 40px;
        }
    }

    .s_t {
        text-align: center;
        font-size: 30px;
        font-weight: 500;
        margin: 30px 0;
    }

    .content {
        padding: 20px 30px 180px;
    }

    .top {
        width: 100%;

        img {
            width: 100%;
        }
    }

    .time {
        background: #f8f9fa;
        font-size: 26px;
        color: #666666;
        padding: 20px 35px;

        .t_a {
            margin-bottom: 15px;
        }

        .t_b {

        }
    }

    .institution {
        font-weight: 400;
        margin-top: 10px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #999999;
    }

    .title {
        // padding-top:25px;
        padding-left: 35px;
        padding-right: 25px;

        .border_btm {
            padding-bottom: 20px;
        }

        .dero {
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            margin-top: 10px;
        }

    }

    .title_top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title_name {
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }

        .sale_num {
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7F7E7E;
        }
    }

    .btn_btm_cont {
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        padding: 20px 0;
        // display: flex;
        // justify-content: flex-end;
        background: #fff;
        z-index: 999;
        border-top: 1px solid #f5f5f5;

        .btn_inner {
            padding: 0 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .order_btn {
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            height: 50px;
            padding: 0 22px;
            line-height: 50px;
            border-radius: 25px;
            border: 1px solid #999999;
            margin-left: 20px;
        }

        .money {
            margin-right: 40px;
            color: #FF6161;
            font-size: 45px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
        }

        .small {
            font-size: 28px;
            margin: 0 5px;
        }
        .submit_btn {
            width: 200px;
            height: 80px;
            line-height: 80px;
            white-space: nowrap;
        }
    }
</style>
